import {Card, Typography, Grid, List} from 'antd';
import {styled} from 'styled-components';
import StoredAuth from '../../../utils/localStorage';
import {RightCircleOutlined} from '@ant-design/icons';
import {MetaMaskAuth} from '../../../utils/MetaMaskAuth';
import {ButtonAction} from '../../ButtonAction'; 

const {Title, Paragraph} = Typography;
const {useBreakpoint} = Grid;


const Wr = styled(Card)`
  width: 90%;
  max-width: 1920px;
  margin-top: 32px;
  @media only screen and (max-width: 767px){
    width: 100%;
    padding: 0 10px;
  }
  @media only screen and (max-width: 425px){
    width: 100%;
  }
`;

const H1Title = styled.div`
  margin: 32px 0 24px;
  color: #313141;
  font-family: "Poppins", sans-serif;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  @media only screen and (max-width: 767px){
      font-size: 28px;
    }
    @media only screen and (max-width: 425px){
      font-size: 24px;
    }
`;
// const Subtitle = styled.div`
//   margin: 32px 0 24px;
//   color: #6E778D;
//   font-family: "Poppins", sans-serif;
//   font-size: 18px;
//   font-weight: bold;
//   text-align: center;
//   @media only screen and (max-width: 767px){
//       font-size: 14px;
//     }
//     @media only screen and (max-width: 425px){
//       font-size: 12px;
//     }
// `;

const Text = styled(Paragraph)`
  color: #313141;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  @media only screen and (max-width: 425px){
      font-size: 12px;
    }
`;

const FormWr = styled.div`
  margin: 0 auto;
  max-width: 780px;
  padding: 0 20px;
  @media only screen and (max-width: 767px){
    padding: 0;
  }
`;

const ButtonWr = styled.div`
  margin: 24px auto;
  max-width: 200px;;
`;


export function ValidatorsContent({ signedToken, userAddress, setUserAddress, isSignPopupShown, isConnected, text, isPopupOpen, setIsPopupOpen, setIsFileUpload, setIsValidatorsPopupOpen,  setIsReadOnlyPopupOpen}) {
  const userData = {...StoredAuth.get()};
  if (userData.validatorsCount === null) {
    userData.validatorsCount = 1;  //default value of validators in form
  }

  const {sm} = useBreakpoint();

  const listData = [
    {text: 'Set up the node in read-only mode. See separate ', button: setIsReadOnlyPopupOpen, buttonText: 'guide', moreTextAfterButton: '.'},
    {text: 'Decide how many validators you want to install. To run one validator you need to stake 2000 SAN tokens by sending them to the bridge address. If you want to run 10 validators it will cost you 20 000 SANs. You can buy tokens here: ', link: 'https://app.1inch.io/#/1/simple/swap/ETH/SAN', linkTitle: '1inch', moreText: '. To withdraw SANs from PoS staking, please leave us a request in ', link2: 'https://discord.gg/SFKgvN3XKF', linkTitle2: 'Discord', moreText2: ' by opening a support ticket.'},
    {text: 'Follow the instructions to install the node in validator mode: ', link: 'https://github.com/santiment/sanr-pos-network#running-sanr-network-node-in-signer-mode', linkTitle: 'github', moreText: '.'},
    {text: 'If you have any questions or issues in setting up the node, check if there is an answer to your question in the troubleshooting ', link: 'https://github.com/santiment/sanr-pos-network#troubleshooting', linkTitle: 'section', moreText: '. Otherwise, create a support ticket in our ', link2: 'https://discord.gg/SFKgvN3XKF', linkTitle2: 'Discord', moreText2: '.'},
    {text: 'Register your validators through the form on the dashboard.'},
    {text: 'Obtain the role of validator in ', link: 'https://discord.gg/SFKgvN3XKF', linkTitle: 'Discord', moreText: '. To do this, go to our ', link2: 'https://discord.gg/SFKgvN3XKF', linkTitle2: 'Discord', moreText2: ' and tag @kimochiman in @sanr-network-testnet channel.'}
  ];

  const handleReadOnlyModal = () => {
    setIsValidatorsPopupOpen(false);
    setIsReadOnlyPopupOpen(true);
  };

  return (
    <Wr>
      <FormWr>
        <H1Title>Start run node with validator</H1Title>
        <Title level={!sm ? 3 :2}>About</Title>
        <Text>A validator node refers to a participant in a blockchain network that performs the task of validating and confirming transactions on the network. Validator nodes play a crucial role in maintaining the security and integrity of the blockchain network by ensuring that all transactions are legitimate and meet the predefined consensus rules.</Text>
        <Text>The number of nodes with validators is limited to 108 nodes.</Text>
        <Text>Each node can have up to 10 validators.</Text>
        <Title level={!sm ? 3 :2}>Reward</Title>
        <Text>A total of 108 nodes with validators will receive rewards. Each node, if it is online, will receive a daily reward of 2 points.</Text>
        <Text>In addition, each node owner will receive an additional 0.2 points per validator. Let’s say you run 10 validators. You will additionally receive 2 points.</Text>
        <Text>At our discretion, bonus rewards in points are provided after passing an important network testing phase.</Text>
        <Text>In addition to this, node owners will receive a 15% APY on staked SAN tokens, which node owners can exchange for a new token after the mainnet launch.</Text>
        <Text><strong>Important!</strong> If the owner of a node with validators does not perform updates on the network in a timely manner, the receipt of rewards is suspended and there is a risk of being excluded from the santiment.network testing program. Also, point rewards could be changed in the future.</Text>
        <Title level={!sm ? 3 :2}>Setup</Title>
        <List
        bordered
        dataSource={listData}
        renderItem={(item) => (
        <List.Item>
          <Typography.Text><RightCircleOutlined /></Typography.Text> {item?.text} <a href={item?.link} target="_blank" rel="noreferrer">{item?.linkTitle}</a>{item?.moreText} <a href={item?.link2} target="_blank" rel="noreferrer">{item?.linkTitle2}</a>{item?.moreText2}{item?.button && <span style={{color: '#1677ff', cursor: 'pointer'}} onClick={handleReadOnlyModal}>{item?.buttonText}</span>}{item?.moreTextAfterButton}
        </List.Item>
      )} />
      <ButtonWr>
        {(!userAddress || !signedToken)
          ? <MetaMaskAuth key={1} userAddress={userAddress} setUserAddress={setUserAddress} isSignPopupShown={isSignPopupShown} isConnected={isConnected}  text={text}/>
          : <ButtonAction isPopupOpen={isPopupOpen} setIsPopupOpen={setIsPopupOpen} text={text} setIsFileUpload={setIsFileUpload} setPopupClose={setIsValidatorsPopupOpen} upload/>
        }
      </ButtonWr>
      </FormWr>
    </Wr>
  )
}